import { postBodyRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest(`/oa/index/getIndexPage`, params)
}

export function add (params) {
  return postBodyRequest(`/oa/index/addIndex`, params)
}

export function deleteStandardList (params) {
  return postBodyRequest(`/oa/index/delete`, params)
}
