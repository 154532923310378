<template>
  <div>

    <!--第一表格-->
    <table border="1" bordercolor="#ccc" style="width:100%">
      <thead>
        <tr align="center">
          <th class="table-title" colspan="10">
            {{addUser.perfName}}
            <a-space class="close-btn">
              <a class="close-btn-a" v-if="addStatus===7" @click="getPerfScoreReCheck">绩效确认 </a>
              <a class="close-btn-a" v-if="addStatus===5" @click="getAssessorScore">确认打分通过 </a>
              <a class="close-btn-a" v-if="addStatus===4" @click="getPerfVerified">确认绩效制定</a>
              <a class="close-btn-a" v-if="addStatus===2&&resData.state.value===0" @click="onAdd(0)">保存</a>
              <a class="close-btn-a" v-if="addStatus===2" @click="onAdd(1)">提交制定审核</a>
              <a class="close-btn-a" v-if="addStatus===3" @click="onPerformanceScore()">提交得分</a>
              <a class="close-btn-a" @click="close">关闭</a>
            </a-space>
          </th>
        </tr>
      </thead>
      <tr class="table-title-content" align="center">
        <th>被考核人</th>
        <td>{{addUser.examinee}}</td>
        <th>工号</th>
        <td>{{addUser.no}}</td>
        <th>岗位</th>
        <td>{{addUser.positionName}}</td>
        <th>考核人</th>
        <td>{{addUser.name}}</td>
        <th>方案有效期
          <a-select v-if="addStatus===2" style="width:100px; margin-right: 50px;" v-model:value="addUser.timeUnit.label" placeholder="请选择有效期">
            <a-select-option v-for="element in timeUnitEnum" :key="element.value" :value="element.value" @click="timeUnitChange(element)">{{element.label}}</a-select-option>
          </a-select>
        </th>
        <td v-if="addStatus===2&&addUser.timeUnit.value===2">
<!--          <a-range-picker :placeholder="['选择开始月份', '选择结束月份']" format="YYYY-MM" :mode="mode2" @panelChange="effectiveDateChange" />-->
          <a-month-picker format="YYYY-MM" :disabled-date="disabledDate" v-model:value="startTime" placeholder="选择开始" @change="timeChange"/>
          ——
          <a-month-picker format="YYYY-MM" :disabled-date="endDisabled" v-model:value="endTime" placeholder="选择结束" @change="endChange"/>
        </td>
        <td v-if="addStatus===2&&addUser.timeUnit.value===1">
          <a-month-picker format="YYYY-MM" :disabled-date="disabledDate" v-model:value="startTime" placeholder="选择月份" @change="timeChange"/>
        </td>

        <td v-if="addStatus!=2">
          {{resData.effectiveDate}} <span v-show="resData.effectiveDateEnd!==''">——{{resData.effectiveDateEnd}}</span>
        </td>
      </tr>
      <tr>
        <td colspan="10" class="prompt-data">
          <div class="prompt-title">【考核规则】</div>
          <div style="color: #6B778C;">
            <div>1、内容：关键绩效指标（KPI）+关键过程事件（KPA）；</div>
            <div>2、总权重为100%。</div>
            <div>3、优秀系数1.2，良好系数1，合格系数0.8，一般系数0.5，不合格系数0。</div>
            <div>备注：KPI指标数量原则上不超过6项，实际比例可根据实际岗位工作职责稍作调整；</div>
          </div>
        </td>
      </tr>
    </table>
    <!--第二表格-->
    <table v-if="addStatus===1" style="width:100%;border-left:1px solid #ccc;border-right: 1px solid #ccc;" border="0">
      <tr class="table-indicators" align="center">
        <th>类型</th>
        <th>序号</th>
        <th >指标名称</th>
        <th>指标定义、定义/公式</th>
        <th>权重%</th>
        <th>目标值</th>
        <th>完成情况</th>
        <th colspan="5">
          <div>评价标准</div>
          <div style="display: flex;justify-content: space-around">
            <div>优秀</div>
            <div>良好</div>
            <div>合格</div>
            <div>一般</div>
            <div>不合格</div>
          </div>
        </th>
        <th>得分</th>
        <th>数据来源</th>
        <th>备注</th>
      </tr>
      <tr v-for="(item,index) in indicatorsData" :key="item.NO" align="center" class="table-indicators-data">
        <th v-if="item.NO <= 1" :rowspan="indicatorsData.length" style="width:7%">关键绩效指标（KPI）{{weightDate.kpi}}%</th>
        <td >{{ item.NO }}</td>
        <td  style="width: 6%;">{{ item.indexName }}</td>
        <td style="width: 10%;"><div  @click="openModal(0,item,index,'description',true)"  class="textOverHide">{{ item.description }}</div> </td>
        <td @click="openModal(0,item,index,'weight',true)">{{ item.weight }}%</td>
        <td style="width: 150px;"><div  @click="openModal(0,item,index,'targetValue',true)"  class="textOverHide">{{ item.targetValue }}</div></td>
        <td style="width: 150px;"><div @click="openModal(0,item,index,'completionValue',true)" class="textOverHide">{{ item.completionValue }}</div></td>
        <td style="width: 100px;"><div  @click="openModal(0,item,index,'excellent',true)" class="textOverHide">{{ item.excellent }}</div></td>
        <td style="width: 100px;"><div @click="openModal(0,item,index,'good',true)" class="textOverHide">{{ item.good }}</div></td>
        <td  style="width: 100px;"> <div @click="openModal(0,item,index,'qualify',true)" class="textOverHide">{{ item.qualify }}</div></td>
        <td style="width: 100px;"><div @click="openModal(0,item,index,'normal',true)" class="textOverHide">{{ item.normal }}</div></td>
        <td style="width: 100px;"><div @click="openModal(0,item,index,'unqualify',true)" class="textOverHide">{{ item.unqualify }}</div></td>
        <td style="width: 100px;" @click="openModal(0,item,index,'score',true)">{{ item.score}}</td>
        <td style="width: 150px;"><div  @click="openModal(0,item,index,'dataSource',true)" class="textOverHide">{{ item.dataSource}}</div></td>
        <td style="width: 150px;"><div @click="openModal(0,item,index,'remark',true)" class="textOverHide">{{ item.remark}}</div></td>
      </tr>
      <tr class="table-indicators" align="center">
        <th>类型</th>
        <th>序号</th>
        <th>指标名称</th>
        <th>目标描述(对完成计划、交付物及完成质量的定义与描述)</th>
        <th>权重%</th>
        <th>目标值</th>
        <th>完成情况(事件完成结果)</th>
        <th colspan="5">评价标准</th>
        <th>得分</th>
        <th>数据来源</th>
        <th>备注</th>
      </tr>
      <tr v-for="(item,index) in indicatorsDataTow" :key="item.NO" align="center" class="table-indicators-data">
        <th v-if="item.NO <= 1" :rowspan="indicatorsDataTow.length" style="width:7%">关键绩效事件（KPA）{{weightDate.kpa}}%</th>
        <td>{{ indicatorsData.length+item.NO }}</td>
        <td>{{ item.indexName }}</td>
        <td align="left" ><div  @click="openModal(1,item,index,'description',true)"  class="textOverHide">{{ item.description }}</div></td>
        <td @click="openModal(1,item,index,'weight',true)">{{ item.weight }}%</td>
        <td ><div class="textOverHide" @click="openModal(1,item,index,'targetValue',true)">{{ item.targetValue }}</div></td>
        <td ><div class="textOverHide" @click="openModal(1,item,index,'completionValue',true)">{{ item.completionValue }}</div></td>
        <td ><div class="textOverHide" @click="openModal(1,item,index,'excellent',true)">{{ item.excellent }}</div></td>
        <td ><div class="textOverHide"  @click="openModal(1,item,index,'good',true)">{{ item.good }}</div></td>
        <td ><div class="textOverHide" @click="openModal(1,item,index,'qualify',true)">{{ item.qualify }}</div></td>
        <td ><div class="textOverHide" @click="openModal(1,item,index,'normal',true)">{{ item.normal }}</div></td>
        <td ><div class="textOverHide"  @click="openModal(1,item,index,'unqualify',true)">{{ item.unqualify }}</div></td>
        <td  @click="openModal(1,item,index,'score',true)">{{ item.score}}</td>
        <td ><div class="textOverHide"  @click="openModal(1,item,index,'dataSource',true)">{{ item.dataSource}}</div></td>
        <td><div class="textOverHide"  @click="openModal(1,item,index,'remark',true)">{{ item.remark}}</div></td>
      </tr>
      <tr class="table-indicators" align="center">
        <th>类型</th>
        <th>序号</th>
        <th>事件类型</th>
        <th colspan="4">事件描述</th>
        <th colspan="4">评价标准</th>
        <th>得分</th>
        <th>数据来源</th>
        <th colspan="2">备注</th>
      </tr>

      <tr v-for="(item,index) in additional" :key="item.NO" align="center" class="table-indicators-data">
        <th v-if="item.NO <= 1" :rowspan="additional.length" style="width:7%">关键事件附加分</th>
        <td>{{item.NO}} </td>
        <td> <div class="textOverHide"  @click="openModal(2,item,index,'indexName',true)">{{item.indexName}}</div></td>
        <td colspan="4"><div class="textOverHide"  @click="openModal(2,item,index,'description',true)">{{item.description}}</div></td>
        <td align="left" v-if="item.NO<=1" rowspan="3" colspan="4">
          <div>1、践行公司价值观，根据具体事件由直级领导、跨级领导、人力行政部负责人共同评定，附加分为1-10分，违反则扣除相应分数；</div>
          <div>2、人才培养，人才输出：所在团队成长1名组长附加2分，输出1名主管，附加5分，输出1名经理，附加10分，输出1名总监，附加20分。</div>
        </td>
        <td> <div class="textOverHide"  @click="openModal(2,item,index,'score',true)">{{item.score}}</div></td>
        <td><div class="textOverHide"  @click="openModal(2,item,index,'dataSource',true)">{{item.dataSource}}</div></td>
        <td  colspan="2"><div class="textOverHide"  @click="openModal(2,item,index,'remark',true)">{{item.remark}}</div></td>
      </tr>
      <tr></tr>
      <tr class="table-indicators-data">
        <th style="padding:8px" colspan="3">得分总计</th>
        <td style="padding:8px" colspan="3">{{totalData.totalScore}}</td>
        <th style="padding:8px">绩效等级</th>
        <td style="padding:8px" colspan="11">{{totalData.perfLevel}}</td>
      </tr>
      <tr class="table-indicators-data">
        <th style="padding:8px" colspan="3">评语</th>
        <td style="padding:8px" colspan="11">{{totalData.comment}}</td>
      </tr>
    </table>
   
    <!--第三表格(自定义添加数据)-->
    <table v-if="addStatus!==1" style="width:100%;border-left:1px solid #ccc;border-right: 1px solid #ccc;" border="0">
      <tr class="table-indicators" align="center">
        <th>类型</th>
        <th>序号</th>
        <th>指标名称</th>
        <th>指标定义、定义/公式</th>
        <th>权重%</th>
        <th>目标值</th>
        <th>完成情况</th>
        <th colspan="5">
          <div>评价标准</div>
          <div style="display: flex;justify-content: space-around">
            <div>优秀</div>
            <div>良好</div>
            <div>合格</div>
            <div>一般</div>
            <div>不合格</div>
          </div>
        </th>
        <th>得分</th>
        <th>数据来源</th>
        <th>备注</th>
      </tr>
      <tr v-for="(item,index) in addIndicatorsData" :key="item.NO" align="center" @click="num=index" class="table-indicators-data">
        <th v-if="item.NO <= 1" :rowspan="addIndicatorsData.length" style="width:7%">关键绩效指标（KPI）{{weightDate.kpi}}%</th>
        <td  class="table-exhibition-serial">{{ item.NO}}</td>
        <td  class="table-exhibition-index">
          <a-select @search="handleSearchKpi" v-if="addStatus===2" show-search v-model:value="item.name.label" placeholder="请选择指标，搜索至少2个字" style="width:100%">
            <a-select-option v-for="element in standardNameKpi" :key="element.value" :value="element.value" @click="standarChange(0,element)">{{element.label}}</a-select-option>
          </a-select>
          <div v-else>{{item.name.label}}</div>
        </td>
        <td class="table-exhibition-index">{{ item.description }}</td>
        <td class="table-exhibition-weight">
          <div style="color:#999"  v-if="addStatus===2&&!item.weight" @click="openModal(0,item,index,'weight')">请输入</div>
          <div v-else-if="addStatus===2&&item.weight" @click="openModal(0,item,index,'weight')">{{ item.weight }}</div>
          <div v-else @click="openModal(0,item,index,'weight',true)">{{ item.weight }}</div>
        </td>
        <td class="table-exhibition-weight">
          <div style="color:#999"  v-if="addStatus===2&&!item.targetValue" @click="openModal(0,item,index,'targetValue')">请输入</div>
          <div class="textOverHide" v-else-if="addStatus===2&&item.targetValue" @click="openModal(0,item,index,'targetValue')">{{ item.targetValue }}</div>
          <div class="textOverHide" v-else @click="openModal(0,item,index,'targetValue',true)">{{item.targetValue}}</div>
        </td>
        <td class="table-exhibition-weight">
          <div style="color:#999"  v-if="addStatus===3&&!item.completionValue" @click="openModal(0,item,index,'completionValue')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===3&&item.completionValue||isMark&&item.completionValue" @click="openModal(0,item,index,'completionValue')">{{ item.completionValue }}</div>
          <div  class="textOverHide" v-else @click="openModal(0,item,index,'completionValue',true)">{{item.completionValue}}</div>
        </td>
        <td class="table-exhibition-evaluate">
          <div style="color:#999"  v-if="addStatus===2&&!item.excellent" @click="openModal(0,item,index,'excellent')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.excellent" @click="openModal(0,item,index,'excellent')">{{ item.excellent }}</div>
          <div  class="textOverHide" v-else @click="openModal(0,item,index,'excellent',true)">{{item.excellent}}</div>
        </td>
        <td class="table-exhibition-evaluate">
          <div style="color:#999"  v-if="addStatus===2&&!item.good" @click="openModal(0,item,index,'good')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.good" @click="openModal(0,item,index,'good')">{{ item.good }}</div>
          <div  class="textOverHide" v-else  @click="openModal(0,item,index,'good',true)">{{item.good}}</div>
        </td>
        <td class="table-exhibition-evaluate">
          <div style="color:#999"  v-if="addStatus===2&&!item.qualify" @click="openModal(0,item,index,'qualify')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.qualify" @click="openModal(0,item,index,'qualify')">{{ item.qualify }}</div>
          <div  class="textOverHide" v-else @click="openModal(0,item,index,'qualify',true)">{{item.qualify}}</div>
        </td>
        <td class="table-exhibition-evaluate">
          <div style="color:#999"  v-if="addStatus===2&&!item.normal" @click="openModal(0,item,index,'normal')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.normal" @click="openModal(0,item,index,'normal')">{{ item.normal }}</div>
          <div  class="textOverHide" v-else @click="openModal(0,item,index,'normal',true)">{{item.normal}}</div>
        </td>
        <td class="table-exhibition-evaluate">
          <div style="color:#999"  v-if="addStatus===2&&!item.unqualify" @click="openModal(0,item,index,'unqualify')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.unqualify" @click="openModal(0,item,index,'unqualify')">{{ item.unqualify }}</div>
          <div  class="textOverHide" v-else @click="openModal(0,item,index,'unqualify',true)">{{item.unqualify}}</div>
        </td>
        <td class="table-exhibition-weight">
          <div style="color:#999"  v-if="addStatus===3&&!item.score" @click="openModal(0,item,index,'score')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===3&&item.score ||isMark&&item.score" @click="openModal(0,item,index,'score')">{{ item.score }}</div>
          <div  class="textOverHide" v-else @click="openModal(0,item,index,'score',true)">{{item.score}}</div>
        </td>
        <td class="table-exhibition-source">
          <div style="color:#999"  v-if="addStatus===2&&!item.dataSource" @click="openModal(0,item,index,'dataSource')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.dataSource" @click="openModal(0,item,index,'dataSource')">{{ item.dataSource }}</div>
          <div  class="textOverHide" v-else @click="openModal(0,item,index,'dataSource',true)">{{item.dataSource}}</div>
        </td>
        <td>
          <div style="color:#999"  v-if="addStatus===2&&!item.remark" @click="openModal(0,item,index,'remark')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.remark" @click="openModal(0,item,index,'remark')">{{ item.remark }}</div>
          <div  class="textOverHide" v-else @click="openModal(0,item,index,'remark',true)">{{item.remark}}</div>
        </td>
        <td class="edit-btn"  v-if="addStatus===2">
          <div class="delete" v-show="addIndicatorsData.length>1" @click="deleteTable(index,1)"><img src="@/assets/images/achievement/delete-btn.png" /></div>
          <div class="add" v-show="item.NO >= addIndicatorsData.length" @click="addTable(1)"><img src="@/assets/images/achievement/add-btn.png" /></div>
        </td>
      </tr>
      <tr class="table-indicators" align="center">
        <th>类型</th>
        <th>序号</th>
        <th>指标名称</th>
        <th>目标描述(对完成计划、交付物及完成质量的定义与描述)</th>
        <th>权重%</th>
        <th>目标值</th>
        <th>完成情况(事件完成结果)</th>
        <th colspan="5">评价标准</th>
        <th>得分</th>
        <th>数据来源</th>
        <th>备注</th>
      </tr>
      <tr v-for="(item,index)  in addIndicatorsDataTow" :key="item.NO" @click="num=index" align="center" class="table-indicators-data">
        <th v-if="item.NO <= 1" :rowspan="addIndicatorsDataTow.length" style="width:7%">关键绩效事件（KPA）{{weightDate.kpa}}%</th>
        <td  class="table-exhibition-serial">{{ addIndicatorsData.length+item.NO }}</td>
        <td  class="table-exhibition-index">
          <a-select  @search="handleSearchKpa" v-if="addStatus===2" show-search v-model:value="item.name.label" placeholder="请选择指标，搜索至少2个字" style="width:100%">
            <a-select-option v-for="element in standardNameKpa" :key="element.value" :value="element.value" @click="standarChange(1,element)">{{element.label}}</a-select-option>
          </a-select>
          <div v-else>{{item.name.label}}</div>
        </td>
        <td align="left"  class="table-exhibition-index">{{ item.description }}</td>
        <!--        <td align="left" style="width:13%">-->
        <!--          <a-input v-model:value="item.description" placeholder="请输入" />-->
        <!--        </td>-->
        <td  class="table-exhibition-weight">
          <div style="color:#999"  v-if="addStatus===2&&!item.weight" @click="openModal(1,item,index,'weight')">请输入</div>
          <div v-else-if="addStatus===2&&item.weight" @click="openModal(1,item,index,'weight')">{{ item.weight }}</div>
          <div v-else  @click="openModal(1,item,index,'weight',true)">{{ item.weight }}</div>
        </td>
        <td class="table-exhibition-weight">
          <div style="color:#999"  v-if="addStatus===2&&!item.targetValue" @click="openModal(1,item,index,'targetValue')">请输入</div>
          <div class="textOverHide" v-else-if="addStatus===2&&item.targetValue" @click="openModal(1,item,index,'targetValue')">{{ item.targetValue }}</div>
          <div class="textOverHide" v-else @click="openModal(1,item,index,'targetValue',true)">{{item.targetValue}}</div>
        </td>
        <td class="table-exhibition-weight">
          <div style="color:#999"  v-if="addStatus===3&&!item.completionValue" @click="openModal(1,item,index,'completionValue')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===3&&item.completionValue||isMark&&item.completionValue" @click="openModal(1,item,index,'completionValue')">{{ item.completionValue }}</div>
          <div  class="textOverHide" v-else @click="openModal(1,item,index,'completionValue',true)">{{item.completionValue}}</div>
        </td>
        <td class="table-exhibition-evaluate">
          <div style="color:#999"  v-if="addStatus===2&&!item.excellent" @click="openModal(1,item,index,'excellent')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.excellent" @click="openModal(1,item,index,'excellent')">{{ item.excellent }}</div>
          <div  class="textOverHide" v-else @click="openModal(1,item,index,'excellent',true)">{{item.excellent}}</div>
        </td>
        <td class="table-exhibition-evaluate">
          <div style="color:#999"  v-if="addStatus===2&&!item.good" @click="openModal(1,item,index,'good')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.good" @click="openModal(1,item,index,'good')">{{ item.good }}</div>
          <div  class="textOverHide" v-else @click="openModal(1,item,index,'good',true)">{{item.good}}</div>
        </td>
        <td class="table-exhibition-evaluate">
          <div style="color:#999"  v-if="addStatus===2&&!item.qualify" @click="openModal(1,item,index,'qualify')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.qualify" @click="openModal(1,item,index,'qualify')">{{ item.qualify }}</div>
          <div  class="textOverHide" v-else @click="openModal(1,item,index,'qualify',true)">{{item.qualify}}</div>
        </td>
        <td class="table-exhibition-evaluate">
          <div style="color:#999"  v-if="addStatus===2&&!item.normal" @click="openModal(1,item,index,'normal')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.normal" @click="openModal(1,item,index,'normal')">{{ item.normal }}</div>
          <div  class="textOverHide" v-else @click="openModal(1,item,index,'normal',true)">{{item.normal}}</div>
        </td>
        <td class="table-exhibition-evaluate">
          <div style="color:#999"  v-if="addStatus===2&&!item.unqualify" @click="openModal(1,item,index,'unqualify')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.unqualify" @click="openModal(1,item,index,'unqualify')">{{ item.unqualify }}</div>
          <div  class="textOverHide" v-else @click="openModal(1,item,index,'unqualify',true)">{{item.unqualify}}</div>
        </td>
        <td class="table-exhibition-weight">
          <div style="color:#999"  v-if="addStatus===3&&!item.score" @click="openModal(1,item,index,'score')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===3&&item.score ||isMark&&item.score" @click="openModal(1,item,index,'score')">{{ item.score }}</div>
          <div  class="textOverHide" v-else @click="openModal(1,item,index,'score',true)">{{item.score}}</div>
        </td>
        <td class="table-exhibition-source">
          <div style="color:#999"  v-if="addStatus===2&&!item.dataSource" @click="openModal(1,item,index,'dataSource')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.dataSource" @click="openModal(1,item,index,'dataSource')">{{ item.dataSource }}</div>
          <div  class="textOverHide" v-else @click="openModal(1,item,index,'dataSource',true)">{{item.dataSource}}</div>
        </td>
        <td>
          <div style="color:#999"  v-if="addStatus===2&&!item.remark" @click="openModal(1,item,index,'remark')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===2&&item.remark" @click="openModal(1,item,index,'remark')">{{ item.remark }}</div>
          <div  class="textOverHide" v-else @click="openModal(1,item,index,'remark',true)">{{item.remark}}</div>
          <!--          <a-input v-model:value="item.remark" placeholder="请输入" />-->
        </td>
        <td class="edit-btn" v-if="addStatus===2">
          <div class="delete" v-show="addIndicatorsDataTow.length>1" @click="deleteTable(index,2)"><img src="@/assets/images/achievement/delete-btn.png" /></div>
          <div class="add" v-show="item.NO >= addIndicatorsDataTow.length" @click="addTable(2)"><img src="@/assets/images/achievement/add-btn.png" /></div>
        </td>
      </tr>
      <tr class="table-indicators" align="center">
        <th>类型</th>
        <th>序号</th>
        <th>事件类型</th>
        <th colspan="4">事件描述</th>
        <th colspan="4">评价标准</th> 
        <th>得分</th>
        <th>数据来源</th>
        <th colspan="2">备注</th>
      </tr>
      <tr v-for="(item,index) in additional" :key="item.NO" align="center" class="table-indicators-data">
        <th v-if="item.NO <= 1" :rowspan="additional.length" style="width:7%">关键事件附加分</th>
        <td>{{item.NO}}</td>
        <td>
          <div style="color:#999"  v-if="addStatus===5&&!item.indexName || addStatus===6&&!item.indexName" @click="openModal(2,item,index,'indexName')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===5&&item.indexName || addStatus===6&&item.indexName" @click="openModal(2,item,index,'indexName')">{{ item.indexName }}</div>
          <div  class="textOverHide" v-else @click="openModal(2,item,index,'indexName',true)">{{item.indexName}}</div>
        </td>
        <td colspan="4">
          <div style="color:#999"  v-if="addStatus===5&&!item.description || addStatus===6&&!item.description" @click="openModal(2,item,index,'description')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===5&&item.description || addStatus===6&&item.description" @click="openModal(2,item,index,'description')">{{ item.description }}</div>
          <div  class="textOverHide" v-else @click="openModal(2,item,index,'description',true)">{{item.description}}</div>
        </td>
        <td align="left" v-if="item.NO<=1" rowspan="3" colspan="4">
          <div>1、践行公司价值观，根据具体事件由直级领导、跨级领导、人力行政部负责人共同评定，附加分为1-10分，违反则扣除相应分数；</div>
          <div>2、人才培养，人才输出：所在团队成长1名组长附加2分，输出1名主管，附加5分，输出1名经理，附加10分，输出1名总监，附加20分。</div>
        </td>
        <td>
          <div style="color:#999"  v-if="addStatus===5&&!item.score || addStatus===6&&!item.score" @click="openModal(2,item,index,'score')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===5&&item.score || addStatus===6&&item.score" @click="openModal(2,item,index,'score')">{{ item.score }}</div>
          <div  class="textOverHide" v-else  @click="openModal(2,item,index,'score',true)">{{item.score}}</div>
        </td>
        <td>
          <div style="color:#999"  v-if="addStatus===5&&!item.dataSource || addStatus===6&&!item.dataSource" @click="openModal(2,item,index,'dataSource')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===5&&item.dataSource || addStatus===6&&item.dataSource" @click="openModal(2,item,index,'dataSource')">{{ item.dataSource }}</div>
          <div  class="textOverHide" v-else @click="openModal(2,item,index,'dataSource',true)">{{item.dataSource}}</div>
        </td>
        <td colspan="2">
          <div style="color:#999"  v-if="addStatus===5&&!item.remark || addStatus===6&&!item.remark" @click="openModal(2,item,index,'remark')">请输入</div>
          <div  class="textOverHide" v-else-if="addStatus===5&&item.remark || addStatus===6&&item.remark" @click="openModal(2,item,index,'remark')">{{ item.remark }}</div>
          <div  class="textOverHide" v-else @click="openModal(2,item,index,'remark',true)">{{item.remark}}</div>
          <!--          <a-input v-model:value="item.remark" placeholder="请输入" />-->
        </td>
      </tr>
      <tr></tr>
      <tr class="table-indicators-data">
        <th style="padding:8px" colspan="3">得分总计</th>
        <td style="padding:8px" colspan="3">
          <a-input v-if="addStatus===5||addStatus===6" v-model:value="totalData.totalScore" type="number">
            <template #suffix>
              <a-tooltip title="计算总分">
                <EnterOutlined style="cursor: pointer;color:#f00" @click="calculation" />
              </a-tooltip>
            </template>
          </a-input>
          <div v-else>{{totalData.totalScore}}</div>
        </td>
        <th style="padding:8px">绩效等级</th>
        <td style="padding:8px" colspan="8">
          <a-select v-if="addStatus===5||addStatus===6" show-search v-model:value="totalData.perfLevel" @change="handleChange" :options="perfLevelOptions" placeholder="请选择绩效等级" style="width:100%">
          </a-select>
          <div v-else>{{totalData.perfLevel}}</div>
          <!-- <a-input v-model:value="totalData.perfLevel" /> -->
        </td>
      </tr>
      <tr class="table-indicators-data">
        <th style="padding:8px" colspan="3">评语</th>
        <td style="padding:8px" colspan="12">
          <a-input v-if="addStatus===5||addStatus===6" v-model:value="totalData.comment" />
          <div v-else>{{totalData.comment}}</div>
        </td>
      </tr>
    </table>
      <a-modal   v-model:visible="visible" :title="titleModal" @ok="handleOk">
      <a-textarea   v-model:value="changeVal" :disabled="areaDisabled" placeholder="请输入" :rows="4" />
    </a-modal>
  </div>

</template>
<script>
import moment from 'moment'
import { onMounted, reactive, toRefs ,ref} from 'vue'
import { useStore } from 'vuex'
import { EnterOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
// import store from '../../../store/index.js'
import { add, detail, perfVerified, assessorScore, performanceScore, perfScoreReCheck } from '@/api/achievement/mineCustom'
import { page as standardListPage } from '@/api/achievement/standardList'
export default {
  components: {
    EnterOutlined
  },
  // addStatus 1. 查看  2编辑  4确认打分通过 5 确认绩效制定
  // addId  id
  // close 关闭
  // isLastMonth 是否启用上个月模板
  props: {
    addStatus: Number,
    addId: String,
    close: Function,
    isLastMonth: Boolean
  },
  setup (props) {
    const store = useStore()
    const state = reactive({
      isMark:false,
      areaDisabled:false,
      titleModal:'',
      changeVal:'',
      visible:false,
      timer: null,
      editShowOne: true,
      editShowTow: true,
      timeData: '',
      startTime: '',
      endTime: '',
      startMom: null,
      mode2: ['month', 'month'],
      num: null,
      numOne: null,
      addStatus: null,
      addId: null,
      isLastMonth: null,
      close: undefined,
      totalData: {
        totalScore: 0,
        grade: '',
        evaluation: ''
      },
      weightDate: {
        kpi: '',
        kpa: ''
      },
      indicatorsData: [], // 关键绩效指标(2)
      indicatorsDataTow: [], // 关键绩效事件(2)
      standardNameKpi: [], // kpa 选择
      timeUnitEnum: [
        {
          value: 1,
          label: '月'
        },
        {
          value: 2,
          label: '多月'
        }
      ],
      standardNameKpa: [], // kpa 选择
      perfLevelOptions: [
        {
          value: 'A',
          label: 'A'
        },
        {
          value: 'B',
          label: 'B'
        },
        {
          value: 'C',
          label: 'C'
        }, {
          value: 'D',
          label: 'D'
        }, {
          value: 'E',
          label: 'E'
        }, {
          value: 'A+',
          label: 'A+'
        }], // 绩效 选择
      addIndicatorsData: [], // 关键绩效指标数据
      additional: [], // 关键事件附加分
      addIndicatorsDataTow: [], // 关键绩效事件数据
      addData: {
        NO: 1,
        name: {
          label: '',
          value: null
        },
        description: '',
        weight: '',
        targetValue: '',
        completionValue: '',
        excellent: '',
        good: '',
        qualify: '',
        unqualify: '',
        score: '',
        dataSource: '',
        remark: ''
      }, // 关键绩效指标数据(添加)
      addDataTow: {
        NO: 1,
        name: {
          label: '',
          value: null
        },
        description: '',
        weight: '',
        targetValue: '',
        completionValue: '',
        excellent: '',
        good: '',
        qualify: '',
        unqualify: '',
        score: '',
        dataSource: '',
        remark: ''
      }, // 关键绩效事件数据(添加)
      addUser: {
        effectiveDate: '',
        timeUnit: {
          value: 1,
          label: '月'
        }
      },
      resData: {
        state: {
          value: 0
        }
      }
    })
    //打开弹窗
    const remarkOpen = ref({
      index:'',
      str:'',
      num:''
    })
    let MUNE = {
      'description':'指标定义、定义/公式',
      'description1':'目标描述',
      'weight':'权重%',
      'targetValue':'目标值',
      'completionValue':'完成情况',
      'excellent':'评判标准-优秀',
      'good':'评判标准-良好',
      'qualify':'评判标准-合格',
      'normal':'评判标准-一般',
      'unqualify':'评判标准-不合格',
      'score':'得分',
      'dataSource':'数据来源',
      'remark':'备注',

    }
    let attachmentMUNE = {
      'indexName':'事件类型',
      'description':'事件描述',
      'score':'得分',
      'dataSource':'数据来源',
      'remark':'备注'
    }
    const openModal = (num,item,index,str,disabled) => {
      if(disabled){
        state.areaDisabled = true
      }else{
        state.areaDisabled = false
      }
      remarkOpen.index = index
      remarkOpen.str = str
      remarkOpen.num = num
      state.visible = true
      state.changeVal = item[str]
      if(num == 0) {
        state.titleModal = '关键绩效指标（KPI）' + `序号${index+1}`+ '-' + MUNE[str]
      }
      if(num == 1){
        if( str == 'description'){
          state.titleModal = '关键绩效事件（KPA）' + `序号${index+1+state.addIndicatorsData.length}`+ '-' + MUNE[str+1]
        }else{
          state.titleModal = '关键绩效事件（KPA）' + `序号${index+1+state.addIndicatorsData.length}`+ '-' + MUNE[str]
        }
        
      }
      if(num == 2){
        state.titleModal = '关键事件附加分'+ `-序号${index+1}` +'-' + attachmentMUNE[str]
      }
    }
    const handleOk = () => {
      if( remarkOpen.num == 0){
        state.addIndicatorsData[remarkOpen.index][remarkOpen.str] = state.changeVal
      }
      if(remarkOpen.num == 1){
        state.addIndicatorsDataTow[remarkOpen.index][remarkOpen.str] = state.changeVal
      }
      if(remarkOpen.num == 2){
        state.additional[remarkOpen.index][remarkOpen.str] = state.changeVal
      }
      state.visible= false
      state.changeVal = ''
    }
    // 计算总分
    const calculation = async () => {
      let num = 0
      await state.addIndicatorsData.forEach(item => {
        if (item.score !== undefined) {
          num += Number(item.score)
        }
      })
      await state.addIndicatorsDataTow.forEach(item => {
        if (item.score !== undefined) {
          num += Number(item.score)
        }
      })
      await state.additional.forEach(item => {
        if (item.score !== undefined) {
          num += Number(item.score)
        }
      })
      state.totalData.totalScore = num
    }
    // 指标名称选择
    const standarChange = (type, element) => {
      if (type === 0) {
        state.addIndicatorsData[state.num].description = element.description
        state.addIndicatorsData[state.num].indexName = element.label
        state.addIndicatorsData[state.num].indexType = 1
      } else {
        state.addIndicatorsDataTow[state.num].description = element.description
        state.addIndicatorsDataTow[state.num].indexName = element.label
        state.addIndicatorsDataTow[state.num].indexType = 2
      }
    }
    // 指标远程搜索
    const handleSearchKpa = (val) => {
      console.log('handleSearchKpa', val)
      var reg = new RegExp('[\u4E00-\u9FA5]+')
      if (reg.test(val) && val.length > 1) {
        shake(state, val,1000, 2)
      }
      // if (val === '') {
      //   standardListPageData(val,2)
      // }
    }
    // 指标远程搜索
    const handleSearchKpi = (val) => {
      console.log('handleSearchKpi', val)
      var reg = new RegExp('[\u4E00-\u9FA5]+')
      if (reg.test(val) && val.length > 1) {
        shake(state,val,1000, 1)
      }
      // if (val === '') {
      //   standardListPageData(val,1)
      // }
    }
    const shake = (_that, val, delay, type) => {
      if (_that.timer !== null) {
        clearTimeout(_that.timer)
      }
      _that.timer = setTimeout(() => {
        standardListPageData(val, type)
      }, delay)
    }
    // 增加表格1
    const addTable = (num) => {
      if (num === 1) {
        state.editShowOne = false
        state.addIndicatorsData.push(JSON.parse(JSON.stringify(state.addData)))
        state.addIndicatorsData[state.addIndicatorsData.length - 1].NO = state.addIndicatorsData.length
        setTimeout(() => {
          state.editShowOne = true
        }, 1)
      } else {
        state.editShowTow = false
        state.addIndicatorsDataTow.push(JSON.parse(JSON.stringify(state.addDataTow)))
        state.addIndicatorsDataTow[state.addIndicatorsDataTow.length - 1].NO = state.addIndicatorsDataTow.length
        setTimeout(() => {
          state.editShowTow = true
        }, 1)
      }
      if (navigator.userAgent.indexOf('WOW64') > 0) { // 兼容360极速浏览器
        setTimeout(() => {
          document.querySelectorAll('.edit-btn').forEach(item => {
            item.style.height = document.querySelectorAll('.table-indicators-data')[0].offsetHeight + 'px'
          })
        }, 0)
      }
    }
    // 删除表格1
    const deleteTable = (index, num) => {
      if (num === 1) {
        state.addIndicatorsData.splice(index, 1)
        state.addIndicatorsData.forEach((item, index) => {
          item.NO = index + 1
        })
      } else {
        state.addIndicatorsDataTow.splice(index, 1)
        state.addIndicatorsDataTow.forEach((item, index) => {
          item.NO = index + 1
        })
      }
    }
    onMounted(() => {
      onTime()
      standardListPageData('',0)
      if (props.close) state.close = props.close
      if (props.addStatus) state.addStatus = props.addStatus
      console.log(props.addStatus);
      if(props.addStatus==5||props.addStatus==6){
        state.isMark  = true
       }
      if (props.isLastMonth) state.isLastMonth = props.isLastMonth
      else {
        state.isLastMonth = false
      }
      state.addIndicatorsData = [JSON.parse(JSON.stringify(state.addData))]
      state.addIndicatorsDataTow = [JSON.parse(JSON.stringify(state.addDataTow))]
      if (state.additional.length <= 0) {
        state.additional = [
          {
            NO: 1,
            type: '',
            describe: '',
            score: '',
            dataSource: '',
            remark: ''
          }, {
            NO: 2,
            type: '',
            describe: '',
            score: '',
            dataSource: '',
            remark: ''
          }]
      }
      //  ...store.state.user.info

      if (state.isLastMonth === true) onDetail()
      if (props.addId) {
        state.addId = props.addId
        onDetail()
      }
      if (state.isLastMonth === false && props.addId === null) {
        state.addUser.examinee = store.state.user.info.name
        state.addUser.no = store.state.user.info.no
        state.addUser.positionName = store.state.user.info.positionName
        state.addUser.perfName = store.state.user.info.orgName + '考核方案'
        state.addUser.name = ''
      }
      // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 Edge/18.18363
      if (navigator.userAgent.indexOf('WOW64') > 0) { // 兼容360极速浏览器
        setTimeout(() => {
          document.querySelectorAll('.edit-btn').forEach(item => {
            item.style.height = document.querySelectorAll('.table-indicators-data')[0].offsetHeight + 'px'
          })
        }, 1)
      }
    })
    // 初始化时间
    const onTime = () => {
      // const data = new Date()
      // let month = data.getMonth() + 1
      // let year = data.getFullYear()
      // if (month < 10) {
      //   month = '0' + month
      // }
      // state.addUser.effectiveDate = year + '-' + month
      // state.effectiveDate[0] = year + '-' + month
      let MM = moment().format('YYYY-MM')
      const DD = moment().format('D')
      if (DD > 5) {
        MM = moment().add(1, 'months').format('YYYY-MM')
      }
      state.startTime = MM
    }
    // 获取详情
    const onDetail = () => {
      detail({ id: state.addId, isLastMonth: state.isLastMonth }).then(res => {
        if (res.code === 10000) {
          state.resData = res.data
          state.addUser.name = state.resData.assessor
          state.addUser.effectiveDate = state.resData.effectiveDate
          state.startTime = state.resData.effectiveDate
          state.addUser.no = state.resData.empNo
          state.addUser.examinee = state.resData.examinee
          state.addUser.orgName = state.resData.orgName
          state.addUser.perfName = state.resData.perfName
          state.addUser.positionName = state.resData.positionName
          state.indicatorsData.length = 0
          state.addIndicatorsData.length = 0
          state.indicatorsDataTow.length = 0
          state.addIndicatorsDataTow.length = 0
          state.additional = []
          state.weightDate.kpi = 0
          state.weightDate.kpa = 0
          if (typeof state.resData.effectiveDateEnd === 'string' && state.resData.effectiveDateEnd !== '') {
            state.addUser.timeUnit = {
              label: '多月',
              value: 2
            }
            state.startTime = state.resData.effectiveDate
            state.endTime = state.resData.effectiveDateEnd
          }
          state.resData.perfDetailInfoList.forEach(e => {
            if (state.isLastMonth === true) {
              e.score = ''
              e.completionValue = ''
            }
            if (e.indexType === 1) {
              // state.indicatorsData.push(JSON.parse(JSON.stringify(e)))
              state.weightDate.kpi = state.weightDate.kpi + e.weight
              state.indicatorsData.push(e)
              state.indicatorsData[state.indicatorsData.length - 1].NO = state.indicatorsData.length
              e.name = {
                label: e.indexName,
                value: e.indexName
              }
              state.addIndicatorsData.push(e)
              state.addIndicatorsData[state.addIndicatorsData.length - 1].NO = state.addIndicatorsData.length
            } else if (e.indexType === 2) {
              state.weightDate.kpa = state.weightDate.kpa + e.weight
              // state.indicatorsDataTow.push(JSON.parse(JSON.stringify(e)))
              state.indicatorsDataTow.push(e)
              state.indicatorsDataTow[state.indicatorsDataTow.length - 1].NO = state.indicatorsDataTow.length
              e.name = {
                label: e.indexName,
                value: e.indexName
              }
              state.addIndicatorsDataTow.push(e)
              state.addIndicatorsDataTow[state.addIndicatorsDataTow.length - 1].NO = state.addIndicatorsDataTow.length
            } else if (e.indexType === 3) {
              state.additional.push(e)
              state.additional[state.additional.length - 1].NO = state.additional.length
            }
          })
          if (state.isLastMonth === true) {
            state.additional = []
            state.resData.state = { value: 0, label: '保存待提交' }
            res.data.totalScore = ''
            res.data.comment = ''
            res.data.perfLevel = ''
          }
          if (state.addIndicatorsData.length < 1) {
            state.addIndicatorsData.push({
              NO: 1,
              name: { label: '', value: null }
            })
          }
          if (state.addIndicatorsDataTow.length < 1) {
            state.addIndicatorsDataTow.push({
              NO: 1,
              name: { label: '', value: null }
            })
          }
          if (state.additional.length < 2) {
            for (let index = state.additional.length; index < 2; index++) {
              state.additional.push({
                NO: index + 1,
                type: '',
                describe: '',
                score: '',
                dataSource: '',
                remark: ''
              })
            }
          }
          state.totalData = {
            totalScore: res.data.totalScore,
            comment: res.data.comment,
            perfLevel: res.data.perfLevel
          }
        } else {
          console.log('错误', res.code)
          props.close()
        }
        console.log(111,state.addIndicatorsDataTow);
      }).catch(err => { console.log('错误', err) })
    }
    // 保存
    const onAdd = (type) => {
      const perfDetailList = state.addIndicatorsData.concat(state.addIndicatorsDataTow)
      for (let i = 0; i < perfDetailList.length; i++) {
        if (perfDetailList[i].completionValue === '' && perfDetailList[i].dataSource === '' && perfDetailList[i].description === '' && perfDetailList[i].excellent === '' &&
          perfDetailList[i].good === '' && perfDetailList[i].qualify === '' && perfDetailList[i].remark === '' && perfDetailList[i].score === '' && perfDetailList[i].targetValue === '' && perfDetailList[i].unqualify === '' && perfDetailList[i].weight === '') {
          perfDetailList.splice(i, 1)
          i--
        }
        if (perfDetailList[i].indexName === null || perfDetailList[i].indexName === '' || perfDetailList[i].indexName === undefined) perfDetailList.splice(i, 1)
      }
      if(!perfDetailList.every( item =>  isNaN(item.weight) == false)){
        message.error('权重请输入数字，请检查后重新输入')
       return
      }
      const performanceSaveDTO = {
        timeUnit: state.addUser.timeUnit.value,
        id: props.addId,
        assessor: state.addUser.name,
        effectiveDate: state.startTime,
        effectiveDateEnd: state.endTime,
        empNo: state.addUser.no,
        examinee: state.addUser.examinee,
        orgName: state.addUser.orgName,
        perfDetailList: perfDetailList,
        perfName: state.addUser.perfName,
        isCommit: type,
        positionName: state.addUser.positionName
      }
      add(performanceSaveDTO).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          props.close()
        }
      }).catch(err => { console.log('错误', err) })
    }
    // 确定打分通过
    const getAssessorScore = () => {
      const perfDetailList = state.addIndicatorsData.concat(state.addIndicatorsDataTow)
      for (let i = 0; i < perfDetailList.length; i++) {
        if (perfDetailList[i].completionValue === undefined && perfDetailList[i].score === undefined) {
          perfDetailList.splice(i, 1)
          i--
        }
      }
      if(!perfDetailList.every( item =>  isNaN(item.score) == false)){
        message.error('打分请输入数字，请检查后重新输入')
       return
      }
      if(!state.additional.every( item =>  isNaN(item.score) == false)){
        message.error('打分请输入数字，请检查后重新输入')
       return
      }
      perfDetailList.forEach( item => {
        item.score = Number( item.score)
      })
      const obj = {
        additionalPointsList: state.additional,
        comment: state.totalData.comment,
        id: state.addId,
        perfLevel: state.totalData.perfLevel,
        totalScore: state.totalData.score,
        detailScoreList:perfDetailList
      }
      assessorScore(obj).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          props.close()
        }
      }).catch(err => { console.log('错误', err) })
    }
    // 被考核人复查打分
    const getPerfScoreReCheck = () => {
      perfScoreReCheck({ id: props.addId }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          props.close()
        }
      }).catch(err => { console.log('错误', err) })
    }
    // 确定通过绩效制定
    const getPerfVerified = () => {
      perfVerified({ id: props.addId }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          props.close()
        }
      }).catch(err => { console.log('错误', err) })
    }
    // 被考核绩效打分
    const onPerformanceScore = () => {
      const perfDetailList = state.addIndicatorsData.concat(state.addIndicatorsDataTow)
      for (let i = 0; i < perfDetailList.length; i++) {
        if (perfDetailList[i].completionValue === undefined && perfDetailList[i].score === undefined) {
          perfDetailList.splice(i, 1)
          i--
        }
      }
      if(!perfDetailList.every( item =>  isNaN(item.score) == false)){
        message.error('得分请输入数字，请检查后重新输入')
       return
      }
      perfDetailList.forEach( item => {
        item.score = Number(item.score)
      })
      performanceScore(perfDetailList).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          props.close()
        }
      }).catch(err => { console.log('错误', err) })
    }
    // 指标数据
    const standardListPageData = (e,type) => {
      standardListPage({
        current: 1,
        size: 200,
        indexName: e
      }).then(res => {
        if (res.code === 10000) {
          if (type === 0 || type === 1) {
            state.standardNameKpi = []
          }
          if (type === 0 || type === 2) {
            state.standardNameKpa = []
          }
          res.data.records.forEach(e => {
            if (e.indexType.value === 1 && (type === 0 || type === 1)) {
              state.standardNameKpi.push({
                label: e.indexName,
                value: e.indexName,
                description: e.description
              })
            } else if (e.indexType.value === 2 && (type === 0 || type === 2)) {
              state.standardNameKpa.push({
                label: e.indexName,
                value: e.indexName,
                description: e.description
              })
            }
          })
        }
      }).catch(err => { console.log('错误', err) })
        .finally(() => { })
    }
    // 搜索
    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }
    const handleChange = value => {
      state.totalData.perfLevel = value
    }
    // 选择时间
    const timeUnitChange = value => {
      state.addUser.timeUnit = {
        label: value.label,
        value: value.value
      }
      if (value.value === 1) state.endTime = ''
      // onTime()
    }
    // 禁用时间
    const disabledDate = current => {
      let MM = moment().format('YYYY-MM')
      const DD = moment().format('D')
      if (DD > 5 && DD < 20) {
        MM = moment().add(1, 'months').format('YYYY-MM')
      }
      return current && current < moment(MM)
    }
    const effectiveDateChange = (val, mode) => {
      state.effectiveDateAll = val
      state.mode2 = [
        mode[0] === 'date' ? 'month' : mode[0],
        mode[1] === 'date' ? 'month' : mode[1]
      ]
      console.log('effectiveDateChange2', val, mode)
    }
    const timeChange = (e, v) => {
      state.startTime = v
      if (e) state.startMom = e.add(1, 'months').format('YYYY-MM')
    }
    const endChange = (e, v) => {
      if (state.startTime === '') {
        message.error('错误：请先选择开始日期')
        state.endTime = ''
      } else {
        state.endTime = v
      }
    }
    const endDisabled = (current) => {
      if (state.startMom === null) {
        let MM = moment().format('YYYY-MM')
        const DD = moment().format('D')
        if (DD > 5 && DD < 20) {
          MM = moment().add(2, 'months').format('YYYY-MM')
        }
        return current && current < moment(MM)
      } else {
        return current && current < moment(state.startMom)
      }
    }
    return {
      ...toRefs(state),
      filter,
      calculation,
      handleChange,
      standarChange,
      timeChange,
      endChange,
      endDisabled,
      deleteTable,
      addTable,
      onTime,
      store,
      onAdd,
      onPerformanceScore,
      onDetail,
      getAssessorScore,
      getPerfVerified,
      standardListPageData,
      getPerfScoreReCheck,
      timeUnitChange,
      disabledDate,
      effectiveDateChange,
      handleSearchKpa,
      handleSearchKpi,
      openModal,
      remarkOpen,
      handleOk
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
