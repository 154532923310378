import { postBodyRequest, getRequest,exportRequest } from '@/utils/axios'
// 绩效列表
export function page (params) {
  return postBodyRequest(`/oa/perf/getPerfBasePage`, params)
}
// 绩效添加
export function add (params) {
  return postBodyRequest(`/oa/perf/savePerformance`, params)
}
// 绩效查看
export function detail (params) {
  return getRequest(`/oa/perf/getPerformance`, params)
}
// 确定打分通过
export function perfScoreVerify (params) {
  return getRequest(`/oa/perf/perfScoreVerify`, params)
}
// 确定通过绩效制定
export function perfVerified (params) {
  return getRequest(`/oa/perf/perfVerified`, params)
}

// 考核人绩效打分
export function assessorScore (params) {
  return postBodyRequest(`/oa/perf/assessorScore`, params)
}

// 被考核绩效打分
export function performanceScore (params) {
  return postBodyRequest(`/oa/perf/performanceScore`, params)
}

// 被考核人复查打分
export function perfScoreReCheck (params) {
  return getRequest(`/oa/perf/perfScoreReCheck`, params)
}
// 绩效导出
export function perfExport (params, fileName) {
  return exportRequest('/oa/perf/export', params, fileName)
}
//修改--月份
export function editMonth (params) {
  return postBodyRequest(`/oa/perf/edit-month`, params)
}
// 修改--提交
export function editSubmit (params) {
  return getRequest(`/oa/perf/edit-submit`, params)
}